<template>
  <base-layout-two page-title="New Shift" page-default-back-link="/shifts">

    <v-form @submit="createShift">
      <base-card title="Shift Details" class="margin-x-8 margin-y-8">
        <ion-grid>
          <ion-row>
            <!-- User -->
            <!-- using v-if length > 0 as a temporary bug fix for ion-select not showing up from http requests -->
            <ion-col>
              <base-input label-text="Employee *">
                <v-field name="user" v-slot="{ field }" v-model="shift.user_id" :rules="required">
                  <select v-bind="field" class="regular-select">
                    <option v-for="user in users" :key="user.id" :value="user.id">
                      {{ user.first_name }} {{ user.last_name }}
                    </option>
                  </select>
                </v-field>
              </base-input>
              <v-error-message name="property" class="error-message">
              </v-error-message>
            </ion-col>

            <!-- Date -->
            <ion-col size="12">
              <base-input label-text="Date *">
                <v-field name="date" v-slot="{ field }" v-model="shift.date" :rules="required">
                  <ion-input name="date" v-bind="field" placeholder="" type="date"></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="date" class="error-message">
              </v-error-message>
            </ion-col>

            <!-- Type -->
            <ion-col size="6">
              <base-input label-text="Day, Night or PTO? *">
                <v-field name="type" v-slot="{ field }" v-model="shift.type" :rules="required">
                  <select v-bind="field" class="regular-select">
                    <option value="day">Day</option>
                    <option value="night">Night</option>
                    <option value="pto">PTO</option>
                  </select>
                </v-field>
              </base-input>
              <v-error-message name="type" class="error-message">
              </v-error-message>
            </ion-col>

            <!-- Lead -->
            <ion-col size="6">
              <base-input label-text="Lead? *">
                <v-field name="lead" v-slot="{ field }" v-model="shift.lead" :rules="required">
                  <select v-bind="field" class="regular-select">
                    <option :value="0">No</option>
                    <option :value="1">Yes</option>
                  </select>
                </v-field>
              </base-input>
              <v-error-message name="lead" class="error-message">
              </v-error-message>
            </ion-col>

            <!-- Absence -->
            <ion-col size="12">
              <base-input label-text="Absence">
                <v-field name="absence" v-slot="{ field }" v-model="shift.absence">
                  <select v-bind="field" class="regular-select">
                    <option value=""></option>
                    <option value="cancelled">Cancelled</option>
                    <option value="approved_sick">Approved - Sick</option>
                    <option value="approved_emergency">Approved - Emergency</option>
                    <option value="approved_other">Approved - Other</option>
                    <option value="unapproved_call_out">Unapproved - Call Out</option>
                    <option value="unapproved_sick">Unapproved - Sick</option>
                    <option value="unapproved_no_show">Unapproved - No Show</option>
                    <option value="unapproved_other">Unapproved - Other</option>
                  </select>
                </v-field>
              </base-input>
              <v-error-message name="absence" class="error-message">
              </v-error-message>
            </ion-col>

            <!-- General Notes -->
            <ion-col size="12">
              <base-input label-text="General notes">
                <v-field name="general_notes" v-slot="{ field }" v-model="shift.general_notes">
                  <ion-input name="general_notes" v-bind="field" placeholder="" type="text" autocapitalize
                    autocorrect></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="general_notes" class="error-message">
              </v-error-message>
            </ion-col>
          </ion-row>
        </ion-grid>
      </base-card>

      <ion-button type="submit" expand="block" class="margin-x-8 margin-y-16" :disabled="isDisabled">
        Save
      </ion-button>
    </v-form>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonInput, IonButton } from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      shift: {
        user_id: null,
        date: null,
        type: "day",
        lead: 0,
        general_notes: "",
      },
      users: [],
      required: yup.string().required(),
    };
  },

  computed: {
    isDisabled() {
      if (!this.shift.user_id || !this.shift.date)
        return true;

      return false;
    },
  },

  watch: {
  },

  async ionViewWillEnter() {
    this.fetchUsers();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    /**
     * Fetch Users
     */
    async fetchUsers() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users?active=1&type=internal`)
        .then((response) => {
          this.users = response.data.users;

          this.users = this.users.sort((a, b) => {
            if (a.first_name < b.first_name)
              return -1;

            if (a.first_name > b.first_name)
              return 1;

            return 0;
          });
        })
        .catch((error) => { console.log(error) });
    },

    /**
     * Create Shift
     */
    async createShift() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/shifts`,
        data: this.shift,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.shift = response.data.shift;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Shift Created", color: "secondary" });

          this.$router.replace(`/shifts`);
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>